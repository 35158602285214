// Lazy load disabled until stable.

// import React from 'react';

// const SignUp = React.lazy(() =>
//     import ('./Lexicon/Authentication/SignUp'));
// const SignIn = React.lazy(() =>
//     import ('./Lexicon/Authentication/SignIn'));
// const LandingPage = React.lazy(() =>
//     import ('./Lexicon/LandingPage'));

import Loadable from "react-loadable";
import Loader from "./App/layout/Loader/index";

const SignUp = Loadable({
  loader: () => import("./Lexicon/Authentication/SignUp"),
  loading: Loader
});

const SignIn = Loadable({
  loader: () => import("./Lexicon/Authentication/SignIn"),
  loading: Loader
});

const LandingPage = Loadable({
  loader: () => import("./Lexicon/LandingPage"),
  loading: Loader
});

const VerificationPage = Loadable({
  loader: () => import("./Lexicon/Authentication/VerificationPage"),
  loading: Loader
});

const route = [
  { path: "/", exact: true, name: "LandingPage", component: LandingPage },
  { path: "/signin", exact: true, name: "Signin", component: SignIn },
  { path: "/signup", exact: true, name: "Signup", component: SignUp },
  { path: "/verification", name: "VerificationPage", component: VerificationPage }
];

export default route;
